<template>
    <div class="ecart-theme-editor">
        <!-- <pre>
            {{pageList}}
        </pre> -->
        <div class="editor-header px-4">
            <div class="row h-100 justify-content-between align-items-center">
                <div class="col-4">
                    <router-link class="btn btn-light " :to="'/dashboard/stores/' + this.$route.params.storeId">
                        Exit
                    </router-link>
                </div>
                <div class="col-3">
                    <div class="form-group m-0">
                        <select name="" id="" class="custom-select">
                            <option :value="page.name" v-for=" (page,index) in pageList" :key="index"
                                class="text-uppercase" v-text="page.name"></option>
                        </select>

                    </div>
                </div>
                <div class="col-4 text-right">
                    <button class="btn btn-success">
                        Save
                    </button>
                </div>
            </div>
        </div>
        <div class="editor-body">
            <div class="editor-panel-left">
                <h6 class="page-title"> Home page</h6>
                <div class="page-content">
                    <button class="btn btn-header btn-light w-100">
                        <i class="far fa-window-restore mr-2"></i>
                        <span>Header</span>
                    </button>
                    <hr>
                    <!-- <pre> {{selectedBuilder}} </pre> -->
                    <div>
                        <draggable class="mb-2" v-model="componentsList" group="builders" @start="drag=true"
                            @end="drag=false">
                            <div v-for="(element,index) in componentsList" :key="index">
                                <component :is="fnGetBuilderComponent(element)" leftMenu
                                    @selectBuilder="setSelectedBuilder" :item="element"
                                    :selected=" selectedBuilder == element.id ">
                                </component>
                            </div>
                        </draggable>
                        <b-dropdown block variant="light" no-caret right menu-class="w-100">
                            <template #button-content>
                                <i class="fa fa-plus-circle mr-2"></i>
                                <span>Add section</span>
                            </template>
                            <b-dropdown-item @click="addBuilder('banner')">
                                <i class="fa fa-image mr-2"></i>
                                <span v-text="'Banner'"></span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="addBuilder('products-slider')">
                                <i class="fa fa-list-alt mr-2"></i>
                                <span v-text="'Product slider'"></span>
                            </b-dropdown-item>
                        </b-dropdown>

                        <!-- <button class="btn btn-header btn-light w-100">
                            <i class="fa fa-plus-circle mr-2"></i>
                            <span>Add section</span>
                        </button> -->
                    </div>
                    <hr>
                    <button class="btn btn-header btn-light w-100">
                        <i class="fa fa-grip-lines mr-2"></i>
                        <span>Footer</span>
                    </button>
                </div>
            </div>
            <div class="editor-viewer">
                <div class="editor-viewer-content">
                    <div v-for="(element, index) in componentsList" :key="index">
                        <component :is="fnGetComponent(element)" :item="element">
                        </component>
                    </div>
                </div>
            </div>
            <div class="editor-panel-right">

                <component :is="fnGetBuilderComponent(element)" :item="element" v-for="(element,index) in componentsList"
                    :key="index" :selected=" selectedBuilder == element.id " @delete="deleteBuilder(index)">
                </component>
                <div v-if="selectedBuilder == null">
                    <h6 class="page-title"> Web editor</h6>
                    <div class="page-content">

                        <p>
                            Edit your Ecart web site
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        mapActions,
        mapMutations,
        mapState
    } from 'vuex'
    import draggable from 'vuedraggable'
    export default {
        components: {
            draggable,
            WeBanner: () => import(`./components/we-banner.vue`),
            WeProductsSlider: () => import(`./components/we-products-slider.vue`),

            // VIEWS
            Banner: () => import(`./views/banner`),
            ProductsSlider: () => import(`./views/products-slider`)
        },
        data() {
            return { 
                pageIndex: 0,
            }
        },
        computed: {
            ...mapState('EcartWebSiteEditor', ['builderList', 'selectedBuilder', 'pageList']),

            arrayItems: {
                get() {
                    return this.builderList;
                },
                set(newList) {
                    console.log(newList);
                    this.setBuildersList(newList);
                }
            },
            componentsList:{
                get() {
                    return  this.pageList.length > 0 ?  this.pageList[this.pageIndex].components : [];
                },
                set(newList) {
                    // console.log(newList);
                    this.setBuildersList(newList);
                }

            }
        },
        methods: {
            ...mapActions('EcartWebSiteEditor', ['fnApiGetStore', 'fnApiGetPages']),
            ...mapMutations('EcartWebSiteEditor', ['setSelectedBuilder', 'addBuilder', 'deleteBuilder',
                'setBuildersList'
            ]),

            fnGetComponent(item) {
                switch (item.type) {
                    case 'banner':
                        return 'banner'
                    case 'products':
                        return 'products-slider'
                        // default:
                        //     return 'fa fa-square';
                }
            },
            fnGetBuilderComponent(item) {
                switch (item.type) {
                    case 'banner':
                        return 'WeBanner'
                    case 'products':
                        return 'WeProductsSlider'
                }
            },

        },
        mounted() {

            this.fnApiGetPages(this.$route.params.storeId);
            // this.fnApiGetStore(this.$route.params.storeId);
        }
    }
</script>

<style lang="scss">
    $shadow: 0px 2px 2px rgba($color: #000000, $alpha: 0.1);
    $border: 1px solid rgba($color: #000000, $alpha: 0.15);

    .ecart-theme-editor {
        height: 100vh;

        .editor-header {
            width: 100%;
            height: 60px;
            background-color: white;
            box-shadow: $shadow;
            z-index: 2;
        }

        .editor-body {
            height: calc(100vh - 60px);
            display: flex;

            .editor-panel-left {
                height: 100%;
                overflow-y: scroll;
                width: 20%;
                border-right: $border;

                .page-title {
                    border-bottom: $border;
                    padding-left: 1.5rem;
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                    font-weight: 600;
                }
            }

            .editor-viewer {
                height: 100%;
                overflow-y: scroll;
                width: 55%;
                padding: 1rem;
                background-color: rgba($color: #000000, $alpha: 0.15);

                .editor-viewer-content {
                    min-height: 100%;
                    border: $border;
                    background-color: white;
                }
            }


            .page-content {
                padding: 0.5rem 1rem;

                .btn {
                    text-align: left;
                }
            }


            .editor-panel-right {
                height: 100%;
                overflow-y: scroll;
                width: 25%;
                max-width: 400px;
                border-left: $border;
            }
        }

    }


    hr {
        border: $border;
        margin: 0.5rem 0 0.5rem 0 !important;
        padding: 0;
    }
</style>